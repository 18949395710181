import { render, staticRenderFns } from "./ProductPublicationStatus.vue?vue&type=template&id=0e122efe"
import script from "./ProductPublicationStatus.vue?vue&type=script&lang=js"
export * from "./ProductPublicationStatus.vue?vue&type=script&lang=js"
import style0 from "./ProductPublicationStatus.vue?vue&type=style&index=0&id=0e122efe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports