<template>
  <div class="md-layout-item productsListing">
    <md-table class="productsListingContent" v-model="products" md-sort="sku" md-sort-order="asc" md-card>

      <md-table-toolbar class="sticky">
        <div class="sectionTitle">
          <h1 class="md-title">
            {{ $t('product.publicationStatusProducts') }}
          </h1>
          <md-button class="md-primary md-raised export-button" @click="downloadProductsPublication"
            :disabled="exporting">
            {{ $t('product.export') }}
          </md-button>
        </div>
        <div class="sectionTitle">
          <h4>{{ $t('product.exportHint') }}</h4>
        </div>
        <div class="optionsFilter">
          <div class="header-tools">
            <!-- <div class="filter">
              <enum-select-vue-material :enum="PublishStatusCodeEnum" :selected.sync="publishStatusCode"
                :label="$t('product.publishStatusCode')" ressourceName="publishStatusCode"></enum-select-vue-material>
            </div> -->
            <md-field md-clearable class="md-toolbar-section-end filter">
              <md-input :placeholder="$t('form.searchBy')" v-model="searchTerm" @input="searchProduct" />
            </md-field>
          </div>
        </div>

      </md-table-toolbar>

      <md-table-empty-state :md-label="$t('product.noProductFound')" />

      <md-table-row slot="md-table-row" slot-scope="{ item }" @click="openProduct(item.ProductId)">
        <md-table-cell :md-label="$t('product.sku')" :md-sort-by="$t('product.property.sku')">{{ item.Sku }}
        </md-table-cell>
        <md-table-cell :md-label="$t('product.majorCategory')">
          <omni-major-category-text :value="item.OmniMajorCategoryId" />
        </md-table-cell>
        <md-table-cell :md-label="$t('product.omniIntermediateCategory')">
          <omni-intermediate-category-text :value="item.OmniIntermediateCategoryId" />
        </md-table-cell>
        <md-table-cell :md-label="$t('product.omniMinorCategory')">
          <omni-minor-category-text :value="item.OmniMinorCategoryId" />
        </md-table-cell>
        <md-table-cell :md-label="$t('product.description')">{{ item.Description }}</md-table-cell>
        <md-table-cell :md-label="$t('product.publishStatusCode')">{{ item.PublishStatusCode === PublishStatusCodeEnum.Web || item.PublishStatusCode === PublishStatusCodeEnum.OmniAndWeb ? $t("app.yes") : $t("app.no") }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <paginate :page-count="pageCount" :click-handler="getAllProducts" :prev-text="'Prev'" :next-text="'Next'"
      :container-class="'pagination'" :page-class="'page-item'">
    </paginate>
  </div>
</template>

<script>
import authenticatedAxios from '@/authenticatedAxios';
import OmniMajorCategorySelect from '@/components/OmniMajorCategorySelect.vue';
import { publishStatusCodeEnumWeb } from '@/models/GeneratedModels/PublishStatusCodeEnumWeb.js';
import { memberTypeEnumWeb } from '@/models/GeneratedModels/MemberTypeEnumWeb.js';
import { debounce } from 'debounce';
import OmniIntermediateCategoryText from '@/components/OmniIntermediateCategoryText.vue';
import OmniMinorCategoryText from '@/components/OmniMinorCategoryText.vue';
import OmniMajorCategoryText from '@/components/OmniMajorCategoryText.vue';

export default {
  name: 'ProductPublicationStatus',
  components: {
    OmniMajorCategorySelect,
    OmniIntermediateCategoryText,
    OmniMinorCategoryText,
    OmniMajorCategoryText
  },
  data: () => ({
    exporting: false,
    selectedProducts: [],
    products: [],
    productPerPage: 12,
    currentPage: 1,
    searchTerm: '',
    majorCategory: -1,
    omniMajorCategoryId: null,
    publishStatusCode: 0,
    PublishStatusCodeEnum: publishStatusCodeEnumWeb,
    MemberTypeEnum: memberTypeEnumWeb
  }),
  computed: {
    hasManagerPermissions() {
      return this.$store.state.app.userInfo.MemberType === this.MemberTypeEnum.Manager;
    },
    pageCount() {
      return Math.ceil(this.$store.state.product.productCount / this.productPerPage);
    }
  },
  created: function () {
    this.getAllProducts(this.currentPage);
  },
  methods: {
    async downloadProductsPublication() {
      if (this.exporting) {
        return;
      }

      this.exporting = true;
      let response = await authenticatedAxios.get('/api/product/GetProductsPublicationFile');
      var blob = new Blob([response.data], { type: 'text/plain' });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.download = 'produits_publication.csv';
      document.body.appendChild(a);
      a.href = url;
      a.click();
      document.body.removeChild(a);
      this.exporting = false;
    },
    openProduct(productId) {
      this.$router.push({ name: `${this.$root.$i18n.locale}.updateProduct`, params: { id: productId } });
    },
    getAllProducts(pageNumber) {
      this.currentPage = pageNumber;
      this.$store.dispatch('product/GetAllProducts', [pageNumber, this.productPerPage, this.searchTerm, this.omniMajorCategoryId, null, this.publishStatusCode, null]);
    },
    searchProduct: debounce(function () {
      this.getAllProducts(1);
    }, 400)
  },
  watch: {
    '$store.state.product.activeProducts': function (val) {
      this.products = val;
    },
    omniMajorCategoryId: function () {
      this.getAllProducts(1);
    },
    publishStatusCode: function () {
      this.getAllProducts(1);
    }
  },
  provide() {
    return {
      validator: this.$validator
    };
  }
};
</script>

<style lang="scss">
  .productsListing{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  .productsListing .md-toolbar{
    padding-top: 1.00rem;
    border-bottom: 1px solid #dadada;
    padding: 1.50rem 3.00rem;
  }
  .productsListing .sectionTitle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width:100%;
    padding-bottom:1.063rem;
  }
  .productsListing .optionsFilter{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width:100%;
  }
  .productsListing .md-title{
    font-size: 1.75rem;
    color: #2a2a2a;
    font-weight: 700;
    margin:0;
  }
  .productsListing .md-field.md-theme-default.md-focused label{
    color:#2a2a2a;
  }
  .productsListing .md-field.md-theme-default.md-focused .md-input{
    color:#2a2a2a;
  }
  .productsListing .md-field.md-theme-default:before{
    background-color: #2a2a2a;
  }
  .productsListing .md-checkbox.md-theme-default.md-checked .md-checkbox-container{
    background-color:#e20613;
    border-color:#e20613;
  }
  .productsListing .md-table.md-theme-default .md-table-alternate-header .md-table-toolbar{
    background-color:rgba(255, 95, 95, 0.2);
    padding: 0.750rem 3.00rem;
  }
  .md-list.md-theme-default .md-selected .md-list-item-content,
  .md-list.md-theme-default .router-link-active .md-list-item-content{
    color:#2a2a2a;
    font-weight:700;
  }

  .productsListing .productsListingContent{
    width:100%;
    height:100%;
    box-shadow:none;
    overflow: inherit;
  }
  .productsListing .sticky{
    background-color:#fff!important;
    position:sticky;
    top:0;
    z-index: 10;
    display:block;
  }
  .productsListing .md-table-alternate-header{
    z-index:20;
  }
  .productsListing .md-table-row{
    cursor:pointer;
  }
  .filterContent{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
  .filterContent > div{
    margin-right:1.00rem;
  }

  .file-select .md-button-content > input[type="file"] {
  display: none;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  padding: 20px;
  border-radius: 4px;
  width:100%;


  > li {
    display: inline; // Remove list-style and block-level defaults
      outline:none;
    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height:1.42857143;
      text-decoration: none;
      color:#fff;
      background-color:#fff;
      border: 1px solid #ddd;
      margin-left: -1px;
      outline:none;
    }
    &:first-child {
      > a,
      > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      > a,
      > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
    z-index: 3;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
    text-decoration:none;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #e20613;
    border-color: #e20613;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
    }
  }
}

.header-tools {
  display: flex;

  .filter {
    margin-right: 10px;
  }
}
  .header-tools .filter .md-layout.md-gutter{
    margin:0;
  }
  .header-tools .filter .md-layout.md-gutter .md-layout-item{
    padding:0;
  }

  .md-theme-default .pagination  a:not(.md-button){
    color:#2a2a2a;
  }
  .md-theme-default .pagination .active  a:not(.md-button){
    color:#ffffff;
  }

  .export-button {
    width: 100%;
    font-weight: bold;
    padding: 1rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    transition: .25s;
    height: auto;
    max-width: 225px;
    box-shadow: none;
    margin: 0;

    &:not([disabled]) {
      color: #fff;
      background-color: #e20613 !important;
    }
  }
</style>
